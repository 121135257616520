import { Outlet } from 'react-router-dom';
import Header from 'components/layout/Header/Header';
import Footer from 'components/layout/Footer/Footer';
import Sticky from 'react-sticky-el/lib/basic-version';
import cx from 'classnames';
import { HeaderMenu } from 'components/layout/Header/HeaderMenu';
import { useLayoutContext } from 'components/layout/layout-context';
import css from './base-layout.module.scss';

export default function Layout() {
  const layoutContext = useLayoutContext();

  return (
    <div className={css.page}>
      <Sticky
        stickyClassName={cx(css.stickyHeader, {
          [css.navigationOpen]: layoutContext.isNavigationOpened,
        })}
        wrapperClassName={css.header}
      >
        <Header />
      </Sticky>

      <HeaderMenu />

      <main className={css.main}>
        <Outlet />
      </main>

      <div className={css.footer}>
        <Footer />
      </div>
    </div>
  );
}
