const items = [
  {
    name: 'Home',
    to: '/#home',
  },
  // }, {
  //   name: 'Marketplace',
  //   to: '#',
  // }, {
  //   name: 'Podcast',
  //   to: '#',
  // }, {
  //   name: 'Blog',
  //   to: '#',
  // }, {
  {
    name: 'Benefits',
    to: '/#benefits',
  },
  {
    name: 'Features',
    to: '/#features',
  },
  {
    name: 'Clients',
    to: '/#clients',
  },
  {
    name: 'About us',
    to: '/#about',
  },
  {
    name: 'Contacts',
    to: '/contacts',
  },
  {
    name: 'FAQ',
    to: '/faq',
  },
];

export default items;
