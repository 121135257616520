import { Button } from 'react-bootstrap';
import css from './close.module.scss';

export const Close = ({ onClick }) => {
  return (
    <Button className={css.root} onClick={onClick} variant='link'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <g clipPath='url(#clip0_6079_4622)'>
          <path
            d='M0 0L24 24'
            stroke='#1F1F1F'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M23 0L-0.333336 24'
            stroke='#1F1F1F'
            strokeWidth='4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_6079_4622'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Button>
  );
};
