import React, { useState, createContext, useContext, useMemo } from 'react';

export const LayoutContextProvider = ({ children }) => {
  const [state, setState] = useState({
    isNavigationOpened: false,
  });

  const openNavigation = () => {
    setState((state) => ({
      ...state,
      isNavigationOpened: true,
    }));
  };

  const closeNavigation = () => {
    setState((state) => ({
      ...state,
      isNavigationOpened: false,
    }));
  };

  const toggleNavigation = () => {
    setState((state) => ({
      ...state,
      isNavigationOpened: !state.isNavigationOpened,
    }));
  };

  const value = useMemo(() => {
    return {
      isNavigationOpened: state.isNavigationOpened,
      openNavigation,
      closeNavigation,
      toggleNavigation,
    };
  }, [state.isNavigationOpened]);

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const LayoutContext = createContext({
  isNavigationOpened: false,
  openNavigation: () => {},
  closeNavigation: () => {},
  toggleNavigation: () => {},
});

export const useLayoutContext = () => useContext(LayoutContext);
