import Tile from "components/ui/Tile/Tile";
import Anchor from "components/ui/Anchor/Anchor";
import "./about.styles.css";
import { ReactComponent as TicketMeta } from "icons/tiketmeta.svg";
import { ReactComponent as LinkedIn } from "icons/linkedin.svg";
import walletIcon from "images/wallet-icon.png";
import walletIcon2x from "images/wallet-icon@2x.png";
import cx from "classnames";
import lax from "libs/lax.js";
import { useEffect } from "react";
import { AspectRatio } from "react-aspect-ratio";

const WalletIcon = ({ className }) => {
  return (
    <span className={cx("about__wallet-icon", className)}>
      <img
        src={walletIcon}
        srcSet={`${walletIcon2x} 2x`}
        width={54}
        height={54}
        alt="Wallet emoji"
        loading="lazy"
      />
    </span>
  );
};

export default function About() {
  useEffect(() => {
    lax.addElements(
      ".about__title",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elCenterY+20"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY", `elCenterY`],
            ["40", 0],
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about__subtitle .d-block",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elInY+200*(index+1)"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY+20", "elInY+200*index"],
            ["40", 0],
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about-tile-horizontal--first",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elInY+400"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY+20", "elInY+400"],
            ["40", 0],
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about-tile-horizontal--second",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elInY+400"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY+20", "elInY+400"],
            {
              799: ["40", 0],
              800: ["-40", 0],
            },
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about-tile-horizontal--third",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elInY+400"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY+20", "elInY+400"],
            ["40", 0],
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about-tile-horizontal--forth",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elInY+400"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY+20", "elInY+400"],
            {
              799: ["40", 0],
              800: ["-40", 0],
            },
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      ".about__text",
      {
        scrollY: {
          opacity: [
            ["elInY+20", "elCenterY+20"],
            [0, 1],
            {
              inertia: 0,
            },
          ],
          translateY: [
            ["elInY", `elCenterY`],
            ["40", 0],
            {
              inertia: 0,
              easing: "easeInOutQuad",
            },
          ],
        },
      },
      {}
    );

    return () => {
      lax.removeElements(".about__us");
      lax.removeElements(".about__title");
      lax.removeElements(".about__subtitle .d-block");
      lax.removeElements(".about__founder-tile--first");
      lax.removeElements(".about__founder-tile--second");
      lax.removeElements(".about__founder-tile--third");
      lax.removeElements(".about__founder-tile--forth");
      lax.removeElements(".about__text");
    };
  }, []);

  return (
    <Anchor id="about">
      <div className="about">
        <div className="about__in container">
          <div className="about__us row">
            <div className="col col-3">
              <h2 className="about__title">About us</h2>
            </div>

            <div className="col col-9">
              <p className="about__text">
                TicketMeta{" "}
                <span className={"about__ticketmeta-logo"}>
                  <TicketMeta />
                </span>{" "}
                is a web3 ticketing solution that helps{" "}
                <span className="about__text--no-wrap">
                  artists, event organizers, and venues digitize their tickets
                </span>{" "}
                in order to cut costs 💸, prevent fraud, and boost{" "}
                <WalletIcon className="about__wallet-icon--mobile" />{" "}
                <span className="about__text--no-wrap">
                  revenue <WalletIcon className="about__wallet-icon--desktop" />{" "}
                  while enhancing the overall
                </span>{" "}
                ticketing experience.
              </p>
              <p className="about__text">
                Our Mission is to provide an innovative and smooth gateway from
                web2 to web3.
              </p>
            </div>
          </div>

          {/* <div className="row about__people">
            <div className="col col-3">
              <h3 className="about__subtitle">
                <span className="d-block">Meet</span>
                <span className="d-block">Our</span>
                <span className="d-block primary--text">Team</span>
              </h3>
            </div>

            <div className="col col-9">
              <div className="row about__founders">
                <div className="col">
                  <div className="about-tile-horizontal about-tile-horizontal--first">
                    <Tile variant="g">
                      <AspectRatio
                        ratio="108/142"
                        className="about__founder-image"
                      >
                        <img
                          src={require("images/founder-1.png")}
                          alt="Irina G.Jad"
                          loading="lazy"
                          width={108}
                          height={142}
                        />
                      </AspectRatio>
                      <div className="about-tile-horizontal__text">
                        <div className="about-tile-horizontal__title">
                          Irina G.Jad
                        </div>
                        <div className="about-tile-horizontal__position">
                          Co-founder •
                          <a
                            href="https://sg.linkedin.com/in/irina-g-jadallah-3a8058175"
                            target="__blank"
                            rel="noreferrer"
                            className="about__linkedin about-horizontal__linkedin"
                            aria-label="LinkedIn"
                          >
                            <span className="linkedin-primary">
                              <LinkedIn />
                            </span>
                          </a>
                        </div>
                        <p className="about-tile-horizontal__description">
                          Expert in the event industry and specialized in
                          Virtual reality events and decentralized business. 15+
                          years in event industry, working with A-list artists
                          and classical musicians.
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>

                <div className="col">
                  <div className="about-tile-horizontal about-tile-horizontal--second">
                    <Tile variant="g">
                      <AspectRatio
                        ratio="108/142"
                        className="about__founder-image"
                      >
                        <img
                          src={require("images/founder-2.png")}
                          alt="Anna Ivanko"
                          loading="lazy"
                          width={108}
                          height={142}
                        />
                      </AspectRatio>
                      <div className="about-tile-horizontal__text">
                        <div className="about-tile-horizontal__title">
                          Anna Ivanko
                        </div>
                        <div className="about-tile-horizontal__position">
                          Co-founder •
                          <a
                            href="https://ae.linkedin.com/in/annivanchenko"
                            target="__blank"
                            rel="noreferrer"
                            className="about__linkedin about-horizontal__linkedin"
                            aria-label="LinkedIn"
                          >
                            <span className="linkedin-primary">
                              <LinkedIn />
                            </span>
                          </a>
                        </div>
                        <p className="about-tile-horizontal__description">
                          Expert in the IT recruitment field, headhunter.
                          Leading a team of software developers, 13+ years in
                          communications, recruitment and employer branding.
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>
                <div className="col">
                  <div className="about-tile-horizontal about-tile-horizontal--third">
                    <Tile variant="g">
                      <AspectRatio
                        ratio="108/142"
                        className="about__founder-image"
                      >
                        <img
                          src={require("images/founder-3.png")}
                          alt="Tiago Y."
                          loading="lazy"
                          width={108}
                          height={142}
                        />
                      </AspectRatio>
                      <div className="about-tile-horizontal__text">
                        <div className="about-tile-horizontal__title">
                          Tiago Y.
                        </div>
                        <div className="about-tile-horizontal__position">
                          Senior FullStack / Solidity Developer
                        </div>
                        <p className="about-tile-horizontal__description">
                          10 + years experience React.js, Solidity, DeFi,
                          Uniswap, NFTs, DApps, JavaScript, TypeScript, PgSQL.
                          Integrates different chains (and following features
                          like LP tokens) into browser wallet extension.
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>

                <div className="col">
                  <div className="about-tile-horizontal about-tile-horizontal--forth">
                    <Tile variant="g">
                      <AspectRatio
                        ratio="108/142"
                        className="about__founder-image"
                      >
                        <img
                          src={require("images/founder-4.png")}
                          alt="Walid Kamhawi"
                          loading="lazy"
                          width={108}
                          height={142}
                        />
                      </AspectRatio>
                      <div className="about-tile-horizontal__text">
                        <div className="about-tile-horizontal__title">
                          Walid Kamhawi
                        </div>
                        <div className="about-tile-horizontal__position">
                          Advisor
                        </div>
                        <p className="about-tile-horizontal__description">
                          25+ years in finance and private equity, has
                          significant experience across various sectors,
                          including technology, media, environmental services
                          and telecommunications.
                        </p>
                      </div>
                    </Tile>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </Anchor>
  );
}
