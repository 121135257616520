import React from 'react';
import Sticky from 'react-sticky-el/lib/basic-version';
import cx from 'classnames';
import { PoliciesNavList } from 'components/policies/nav-list';
import Anchor from 'components/ui/Anchor/Anchor';
import ScrollToTop from 'utils/ScrollToTop';
import { Content } from 'components/Content/Content';
import css from './PrivacyPolicy.module.scss';

const PrivacyPolicyPage = () => {
  return (
    <>
      <ScrollToTop />
      <section id='privacy-policy' className={css.root}>
        <div className='container'>
          <div id='cookie-boundary-element' className={cx('row', css.grid)}>
            <div className={cx('col', css.left)}>
              <Sticky
                boundaryElement='#cookie-boundary-element'
                hideOnBoundaryHit={false}
                wrapperClassName={css.stickyNavigation}
              >
                <div className={css.stickyWrapper}>
                  <div className={css.stickyScroller}>
                    <PoliciesNavList
                      containerSelector='#privacy-policy'
                      defaultActiveKey='privacy'
                    />
                  </div>
                </div>
              </Sticky>
            </div>
            <div className={cx('col', css.right)}>
              <Content>
                <Anchor id='introduction'>
                  <h2 className='h3 spacing-top-0'>Introduction</h2>
                  <p>
                    Welcome to Ticketmeta (hereinafter referred to as
                    “Ticketmeta”) Global Privacy Policy ("Privacy Policy").
                    Please take a few moments to read this Privacy Policy
                    carefully before providing us with any personal information
                    about you or anybody else.
                  </p>
                  <p>
                    Ticketmeta is the controller and responsible for your
                    personal data (collectively referred to as “Ticketmeta”,
                    “we”, “us” TicketMeta (the and, more broadly, by using any
                    of our services (the "Services,") which include the Website.
                  </p>
                  <p>
                    We take the protection of your personal information very
                    seriously, and we hope that this privacy policy will help
                    you understand what information we collect, how we use it,
                    and what rights you have in connection to it. This Privacy
                    Policy applies to Ticketmeta and all Ticketmeta services and
                    its website.
                  </p>
                  <p>
                    By using Ticketmeta website and services, you consent to the
                    collection, storage, processing, and transfer of your
                    personal information as outlined in this Privacy Policy.
                    Please, take some time to read the following carefully to
                    gain a better understanding of how we collect, view, and
                    utilize your personal information because it is essential.
                    If you do not agree with any of the terms and conditions in
                    this privacy policy, please desist from using our services
                    and the website immediately.
                  </p>
                  <p>
                    You equally confirm that you are of legal age to access and
                    use our website and services in accordance with its terms
                    and conditions.
                  </p>
                  <p>
                    If a minor supplies Ticketmeta with personal information
                    without his or her parent's or guardian's consent (as
                    defined by applicable law), the parent or guardian may
                    contact Ticketemeta to request for the removal or deletion
                    of the information.
                  </p>
                </Anchor>

                <Anchor id='the-aim-of-this-privacy-policy'>
                  <h2>The aim of this privacy policy</h2>
                  <p>
                    The main aim of this Privacy Notice is to explain how
                    Ticketmeta, and any of its subsidiaries, and international
                    branches (hereinafter referred to as "Ticketmeta" "us,"
                    "we," or "our") collect, use, and share information about
                    you through our online interfaces (collectively referred to
                    herein as the "Site").
                  </p>
                  <p>
                    This Privacy Policy explains your privacy rights and how the
                    EU General Data Protection Regulation ("GDPR") the UK GDPR,
                    the UK-Brexit privacy law, any other privacy law, protects
                    you.
                  </p>
                  <p>
                    Please contact us at{' '}
                    <a
                      href='mailto:admin@ticketmeta.io'
                      target='__blank'
                      rel='noopener'
                    >
                      admin@ticketmeta.io
                    </a>{' '}
                    if you have any questions or concerns about our Privacy
                    Notice. Our Terms and Conditions also apply to your usage of
                    our services and website with regards to certain terms used
                    but not defined in this Privacy Notice.
                  </p>
                  <p>
                    Ticketmeta is a UAE based company with a registered office
                    at UAE Compass Building, Al Shohada Road, AL Hamra
                    Industrial Zone-FZ N45001091. Ticketmeta is the data
                    controller of all Personal Data (as defined below) collected
                    via this website and of certain Personal Data collected from
                    third parties.
                  </p>
                </Anchor>

                <Anchor id='the-data-we-collect-about-you'>
                  <h2>The data we collect about you</h2>
                  <p>
                    This Privacy Policy applies to information we obtain from
                    you via our services and the site. Some of our Site's
                    functionality can be accessed without revealing any Personal
                    Data, however Personal Data is necessary for Services. You
                    may be required to provide, or we may collect, "Personal
                    Data" (which may also be referred to as "personally
                    identifiable information" or "personal information") for you
                    are to be able to use certain features on our Site.
                  </p>
                  <p>
                    “<b>Personal data</b>”, often known as personal information,
                    refers to any information on a living person who can be
                    identified. This is a wide term that encompasses the kind of
                    personal data we've discussed below. It excludes information
                    that cannot be used to identify a specific individual, such
                    as the registration number of a company.
                  </p>
                  <p>
                    A "<b>Data Subject</b>" is a person who may be identified by
                    personal data, either directly or indirectly. This is
                    commonly accomplished using an identifier like a name,
                    identification number, location data, an online identifier,
                    or one or more elements particular to that natural person's
                    physical, physiological, genetic, mental, economic,
                    cultural, or social identity.
                  </p>
                  <p>
                    We will collect, use, store, and transfer different types of
                    personal data about you depending on whether and how you use
                    our Services, Site, or App (if any), which we have divided
                    into categories as follows:
                  </p>

                  <div className='table'>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>
                          Personal Information
                        </h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>first name,</li>
                          <li>maiden name,</li>
                          <li>last name,</li>
                          <li>username or similar identifier,</li>
                          <li>title,</li>
                          <li>date of birth and gender,</li>
                          <li>
                            biometric information, including a visual image of
                            your face,
                          </li>
                          <li>national identity cards,</li>
                          <li>
                            passports, driving licenses or other forms of
                            identification documents.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>
                          Data on Social Identity
                        </h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>your group/company data,</li>
                          <li>
                            information on referrals related to you, • political
                            background,
                          </li>
                          <li>close connections,</li>
                          <li>behavioural data,</li>
                          <li>risk assessment,</li>
                          <li>compliance assessment.</li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>Contact Data</h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>residence details,</li>
                          <li>billing address,</li>
                          <li>delivery address,</li>
                          <li>home address,</li>
                          <li>work address,</li>
                          <li>email address and telephone numbers,</li>
                          <li>proof of address documentation.</li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>
                          Data from Transactions
                        </h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>details about payments to and from you,</li>
                          <li>
                            other details of any transactions you enter into
                            using our Services, the Site.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>Profile Data</h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>your username and password,</li>
                          <li>your identification number as our user,</li>
                          <li>
                            information on whether you have an account with us,
                            and the email associated with your account,
                          </li>
                          <li>requests by you for products or services,</li>
                          <li>your interests, preferences, and feedback</li>
                          <li>
                            other information generated by you when you
                            communicate with us, for example when you address a
                            request to our customer support.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>Usage Data</h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>
                            information about how you use the Site, our
                            Services, other applications made available by us to
                            you, including:
                          </li>
                          <li>device download time,</li>
                          <li>install time,</li>
                          <li>interaction type and time,</li>
                          <li>event time, name, and source.</li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>
                          Data on Marketing and Communications
                        </h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>
                            our preferences in receiving marketing from us or
                            third parties,{' '}
                          </li>
                          <li>your communication preferences,</li>
                          <li>your survey responses.</li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>Technical Data</h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>internet connectivity data,</li>
                          <li>internet protocol (IP) address,</li>
                          <li>operator and carrier data,</li>
                          <li>login data,</li>
                          <li>browser type and version,</li>
                          <li>device type, category, and model,</li>
                          <li>time zone setting and location data,</li>
                          <li>language data,</li>
                          <li>application version and SDK version,</li>
                          <li>browser plug-in types and versions,</li>
                          <li>operating system and platform,</li>
                          <li>
                            diagnostics data such as crash logs and any other
                            data we collect for the purposes of measuring
                            technical diagnostics, and
                          </li>
                          <li>
                            other information stored on or available regarding
                            the devices you allow us access to when you visit
                            the Site or our Services.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='tableRow'>
                      <div className='tableColumn'>
                        <h3 className='h4 spacing-top-0'>Financial Data</h3>
                      </div>
                      <div className='tableColumn'>
                        <ul>
                          <li>bank account,</li>
                          <li>payment card details,</li>
                          <li>virtual currency accounts,</li>
                          <li>external account details,</li>
                          <li>source of funds and related documentation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h3 className='spacing-top-lg'>
                    Personal data in special categories
                  </h3>
                  <p>
                    The legislation that applies to you provides additional
                    protection for some types of sensitive personal data.
                  </p>
                  <p>
                    "Special categories" of personal data are what they're
                    termed. The following are the special categories:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      Personal information that reveals a person's racial or
                      ethnic origin.
                    </li>
                    <li>Opinions on politics</li>
                    <li>Beliefs in religion or philosophy.</li>
                    <li>Membership in a labor union.</li>
                    <li>
                      Processed genetic and biometric data with the goal of
                      uniquely identifying a natural individual.
                    </li>
                    <li>Health-related information.</li>
                    <li>
                      Information about a person's sexual life or sexual
                      orientation.
                    </li>
                  </ul>
                  <p>
                    We will only use special categories of personal data for a
                    specific purpose only when necessary and if we can meet both
                    the lawful basis requirements listed below, as well as at
                    least one of the extra conditions:
                  </p>
                  <ul className='spacing-top'>
                    <li>You have given your explicit permission.</li>
                    <li>
                      Processing refers to personal information that you have
                      knowingly made public.
                    </li>
                    <li>
                      Processing is required to create, exercise, and defend
                      legal claims.
                    </li>
                  </ul>
                  <p>
                    You must provide us with true, complete, and accurate
                    personal information, and you must inform us of any changes
                    thereof.
                  </p>
                </Anchor>

                <Anchor id='what-you-agree-to-by-using-our-site'>
                  <h2>What You Agree to By Using Our Site</h2>

                  <p>
                    It is important that you know the legal basis for using your
                    personal information. They include:
                  </p>

                  <ul className='spacing-top'>
                    <li>
                      To fulfill our obligations under any contract we may have
                      with you (for example, to comply with the Terms of Use of
                      our Site, which you accept by browsing our website, and/or
                      to comply with our contract to provide you with an NFT to
                      you, or any other organization that may be providing you
                      with access to our Services); or
                    </li>
                    <li>To comply with legal requirements; or</li>
                    <li>
                      Our legitimate interests or the legitimate interests of
                      others require the use of your personal information. It is
                      in our legitimate interests to:
                      <ul>
                        <li>carry out research and statistical analysis;</li>
                        <li>carry out marketing and business development;</li>
                        <li>
                          for internal administrative and auditing purposes;
                        </li>
                        <li>operate our Site and provide our Services;</li>
                        <li>
                          select appropriately skilled and qualified suppliers;
                        </li>
                        <li>
                          build relationships with partners and educational
                          institutions; and,
                        </li>
                        <li>run, grow and develop our business;</li>
                      </ul>
                    </li>
                    <li>
                      To send you some communications or collect certain
                      information from you, if your local law requires us to
                      rely on consent.
                    </li>
                  </ul>
                </Anchor>

                <Anchor id='how-we-collect-your-data'>
                  <h2>How We Collect Your Data</h2>
                  <p>
                    We acquire information from and about you through a variety
                    of means, including:
                  </p>
                  <h3>Direct Interactions</h3>
                  <p>
                    By directly interacting with us, such as by filling out
                    forms, by email, or otherwise, you may provide us with your
                    Identity Data, Social Identity Data, Contact Data, Financial
                    Data, Profile Data, and Marketing and Communications Data.
                  </p>
                  <p>
                    This includes any personal information you give us when you:
                  </p>

                  <ul className='spacing-top'>
                    <li>visit our Site or other platforms;</li>
                    <li>apply for our Services;</li>
                    <li>create an account;</li>
                    <li>make use of any of our Services;</li>
                    <li>
                      request marketing to be sent to you, for example by
                      subscribing to our newsletters;
                    </li>
                    <li>
                      enter a competition, promotion, or survey, including
                      through social media channels;
                    </li>
                    <li>give us feedback/reviews or contact us.</li>
                  </ul>

                  <h3>Automated technologies or Interactions</h3>
                  <p>
                    We will automatically collect Technical Data about your
                    equipment, browsing actions, and habits as you engage with
                    us via our Site or platforms. Cookies, server logs, and
                    other similar technologies are used to collect this personal
                    data. Transactional Data will also be collected. If you
                    visit other websites that use our cookies, we may get
                    Technical Data and Marketing and Communications Data about
                    you. More information about how we use cookies can be found
                    in our Cookies Policy.
                  </p>

                  <h3>Third parties or publicly available sources</h3>
                  <p>
                    We also get information about you from other parties or
                    publicly available sources. Among these sources are:
                  </p>
                  <ul className='spacing-top'>
                    <li>fraud and crime prevention agencies,</li>
                    <li>a customer referring you,</li>
                    <li>public blockchain,</li>
                    <li>
                      publicly available information on the Internet (websites,
                      articles, social media etc.)
                    </li>
                  </ul>
                </Anchor>

                <Anchor id='how-we-use-your-data'>
                  <h2>How We Use Your Data</h2>

                  <p>
                    <b>Providing the Site and our Services:</b> We use the
                    Personal Data you give us to allow you to access and use the
                    Site, as well as to provide you with any information,
                    products, or services you request.
                  </p>
                  <p>
                    <b>Security and technical support:</b> Personal Data may be
                    used to provide you with technical help and to protect the
                    security of our Services and the Site.
                  </p>
                  <p>
                    <b>Identity Verification:</b> We may use the Personal Data
                    we collect to validate your identity and authenticate the
                    submissions made on the Site were made by you, that require
                    identity verification. A third-party identity verification
                    firm could be hired to provide this service.
                  </p>
                  <p>
                    <b>Communications with or from Ticketmeta:</b> We may use
                    the information you give when you send us an email message
                    or otherwise contact us to reply to your communication
                    and/or as specified in this Privacy Policy. Where we are
                    legally permitted to do so, we may also archive this
                    information and/or utilize it for future communications with
                    you. When we send you emails, we may keep track of how you
                    interact with them (such as when you open an email or click
                    on a link inside an email). We use this information to
                    improve and customize our communications to you.
                  </p>
                  <p>
                    <b>Communications with our Buisness Partners:</b> We may
                    share your Personal Data with our business partners so that
                    they can share information about their products and services
                    that may be of interest to you when they are legally
                    permitted to do so.
                  </p>

                  <h2>Disclosure of Your Data</h2>
                  <p>
                    To execute tasks and deliver the Services to you on our
                    behalf, we might transfer your personal data to our
                    third-party service providers, agents, subcontractors, and
                    other related organizations and affiliates.  When using
                    third-party service providers, they are required to respect
                    the security of your personal data and treat it in a legal
                    manner.
                  </p>
                  <p>
                    Based on the following legal grounds, we may process or
                    share your data:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      If you have granted us express consent to use your
                      personal information for a specific purpose, we may handle
                      your data. (<b>Consent</b>)
                    </li>
                    <li>
                      When processing your data is reasonably necessary to
                      achieve our legitimate business interests. (
                      <b>Legitimate Business Interests</b>)
                    </li>
                    <li>
                      If we have a contract with you, we may use your personal
                      information to carry out the terms of that contract. (
                      <b>Contractual Performance</b>)
                    </li>
                    <li>
                      To comply with applicable legislation, governmental
                      demands, a judicial procedure, court order, or legal
                      process, such as in response to a court order or a
                      subpoena, we may release personal information (including
                      in response to public authorities to meet national
                      security or law enforcement requirements). (
                      <b>Legal Obligations</b>)
                    </li>
                    <li>
                      If we believe it is necessary to investigate, prevent, or
                      act in connection with potential policy violations,
                      suspected fraud, situations involving potential threats to
                      the safety of any person, or illegal activities, or as
                      evidence in litigation in which we are involved. (
                      <b>Vital Interests</b>)
                    </li>
                  </ul>
                  <p>
                    In the following circumstances, we may need to process your
                    data or share your personal information with third parties:
                  </p>
                  <ol className='spacing-top'>
                    <li>
                      <span>
                        <b>Transferring a business:</b> We may share or transfer
                        your information in connection with or during any
                        merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business by
                        another company, or during any merger, sale of company
                        assets, financing, or acquisition of all or a portion of
                        our business by another company.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Advertisers:</b> When you access or use the Website,
                        we may utilize third-party advertising businesses to
                        show ads. These companies may use information from web
                        cookies and other tracking technologies about your
                        visits to our Site and other platforms to serve you
                        advertising for services that you might be interested
                        in.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Affiliates:</b> We may share your information with
                        our affiliates, in which case we will require them to
                        adhere to the terms of this Privacy Policy. Our parent
                        company, as well as any subsidiaries ( if there are
                        any), joint venture partners, or other firms that we
                        control or share shared control with, are considered
                        affiliates.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Third-Party Service Providers:</b> We may share your
                        information with third-party service providers,
                        contractors, or agents who perform services for us or on
                        our behalf and need access to certain information to do
                        so. Payment processing, data analysis, email delivery,
                        hosting services, customer support, and marketing
                        activities are just a few examples. We may allow certain
                        third parties to use tracking technology on the Website
                        to collect data on our behalf about how you interact
                        with it over time. This information can be used to
                        evaluate and track data, determine the popularity of
                        specific content, pages, or features, and gain a better
                        understanding of online activity. We do not disclose,
                        sell, rent, or lease your personal information unless
                        otherwise stated in this Privacy Policy.
                      </span>
                    </li>
                  </ol>
                </Anchor>

                <Anchor id='international-privacy-practices'>
                  <h2>International Privacy Practices</h2>
                  <p>
                    Because some of our external third parties and affiliates
                    might be located outside of the EEA or the UK, their
                    processing of your personal data will need a data transfer
                    outside of the EEA or the UK. This will necessitate the
                    transfer of your personal information outside of the
                    European Economic Area (EEA) or the United Kingdom as the
                    case may be.
                  </p>
                  <p>
                    We provide a similar level of protection for your personal
                    data if we send it outside of the EEA or the UK by
                    implementing at least one of the following safeguards:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      The European Commission has determined that the country to
                      which we transfer your personal data provides an adequate
                      level of protection for personal data;
                    </li>
                    <li>
                      A specific contract approved by the European Commission
                      that provides safeguards for the processing of personal
                      data, known as Standard Contractual Clauses.
                    </li>
                  </ul>
                  <p>
                    Be informed that we manage and run our site and Services
                    from UAE. Your Personal Data may be sent outside of the
                    country in which you reside or are located, including to the
                    event organizers and artists, to provide our Services to
                    you.
                  </p>
                  <p>
                    If you would like more information on the exact method we
                    use to transfer your personal data outside of the EEA or the
                    UK, please get in touch with us at admin@ticketmeta.io
                  </p>
                  <p>
                    We are dedicated to maintaining the privacy and secrecy of
                    Personal Data throughout transit. To the extent permitted by
                    applicable law, we take the necessary measures to offer the
                    same level of protection for the processing carried out in
                    any such countries as you would have within the EEA or other
                    regions if you reside in the EEA, the United Kingdom,
                    Switzerland, or another region that offers comparable
                    protections.
                  </p>
                  <h3>Users in California</h3>
                  <p>
                    Depending on your geographical location and citizenship,
                    your rights are subject to local data privacy law
                    regulations. These rights may include:
                  </p>
                  <ol className='spacing-top'>
                    <li>
                      <span>
                        <b>
                          “Right to Access” (PIPEDA , GDPR article 15,
                          CCPA/CPRA, CPA, VCDPA, CTDPA, UCPA,LGPD,POPIA, UK
                          GDPR,PDPL)
                        </b>{' '}
                        — You have the right to request to know more about the
                        categories and specific pieces of personal information
                        that we have collected about you and access a copy of
                        your personal information.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>
                          “Right to Rectification” (PIPEDA, GDPR article 16,
                          CCPA/CPRA, CPA, VCDPA, CTDPA, UCPA,LGPD,POPIA, UK
                          GDPR, PDPL)
                        </b>{' '}
                        — You have the right to have inaccurate personal
                        information about you corrected.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>
                          “Right to Deletion” (GDPR article 17, CCPA/CPRA, CPA,
                          VCDPA, CTDPA, UCPA,LGPD,POPIA, UK GDPR)
                        </b>{' '}
                        — You have the right to request deletion of personal
                        information that we have collected about you.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>“Right to Non-Discrimination”</b> — If you choose to
                        exercise any of your rights under CCPA, Ticketmeta will
                        treat you like all other users. In other words, there is
                        no penalty for exercising your rights under CCPA.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>“Right to Opt-Out”</b> - You have the right to opt
                        out of the sale of your personal information.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Right to Object (GDPR art 21, LGPD, POPIA, PDPL)</b>{' '}
                        You have the right to object to the processing of your
                        personal data where we are relying on a legitimate
                        interest (or those of a third party) and there is
                        something about your particular situation that makes you
                        want to object to processing on this ground because you
                        believe it impacts on your fundamental rights and
                        freedoms; in some cases, we may demonstrate that we have
                        compelling legitimate grounds to process your data that
                        override your rights and freedoms; you also have the
                        right to obstruct processing of your personal data where
                        we are relying;
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>
                          Right to Data Portability (PIPEDA, GDPR art20 ,LGPD ,
                          UK GDPR)
                        </b>{' '}
                        you have the right to request the transfer of your
                        personal data to you or a third party, and we shall give
                        your personal data in a structured, commonly used,
                        machine-readable format to you or a third party you
                        choose (where technically practicable); Please note that
                        this privilege only applies to automated information
                        that you gave us permission to use or that we utilized
                        to fulfil a contract with you.
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Right to Complaint (GDPR Article 77, LGPD, POPIA)</b>{' '}
                        you have the right to complain for any alleged breach
                        that should be reported to the appropriate authority,
                        and damages should be sought through a competent court.
                      </span>
                    </li>
                  </ol>

                  <h2>Withdrawing Consent</h2>
                  <p>
                    You have the right to withdraw your consent at any time,
                    free of charge, such as where you wish to opt out from
                    marketing messages that you receive from us;
                  </p>
                  <p>
                    However, if you withdraw your consent, we may not be able to
                    provide certain Services to you, but we will notify you if
                    this is the case at the time you withdraw your consent;
                    nevertheless, this will not affect the lawfulness of any
                    processing carried out before you withdraw your consent. How
                    to exercise your rights
                  </p>
                  <p>
                    You can make a request to exercise any of those rights in
                    relation to your personal data by sending the request to
                    this email address admin@ticketmeta.io
                  </p>
                  <p>
                    For your own privacy and security, we may require you to
                    prove your identity before providing the required
                    information.
                  </p>
                  <p>
                    <b>Right to Complaint:</b> you have the right to complain
                    for any alleged breach that should be reported to the
                    appropriate authority, and damages should be sought through
                    a competent court.
                  </p>
                  <p>
                    All requests shall be considered and responded to in
                    compliance with applicable data protection legislation.
                  </p>
                </Anchor>

                <Anchor id='confidentiality-and-security-of-personal-data'>
                  <h2>Confidentiality and Security of Personal Data</h2>
                  <p>
                    We cannot guarantee the security of Personal Data since the
                    Internet is not a 100 percent safe environment, and there is
                    a chance that an unauthorized third party will find a way to
                    circumvent our security mechanisms or that the transmission
                    of your information over the Internet will be intercepted.
                    We will protect your Personal Data with industry-standard
                    physical, technical, and administrative security measures,
                    and we will not share it with third parties unless otherwise
                    stated in this Privacy Notice, or unless disclosure is
                    required by law in special circumstances, such as a physical
                    threat to you or others. It is your duty to keep your log-in
                    information secure. Please keep in mind that most e-mail
                    communications are not encrypted and should not be
                    considered safe.
                  </p>
                  <p>
                    While any data shared over the internet carries a risk of
                    being accidentally lost, misused, damaged, accessed in an
                    unauthorized or unlawful manner, altered, or disclosed, we
                    have put in place appropriate security measures to prevent
                    your personal data from being accidentally lost, misused,
                    damaged, accessed in an unauthorized or unlawful manner,
                    altered, or disclosed. Furthermore, we restrict access to
                    your personal data to employees, agents, contractors, and
                    other third parties with a valid business need to know. They
                    will only process your personal data in accordance with our
                    instructions and are bound by strict confidentiality
                    obligations.
                  </p>
                  <p>
                    We shall implement the following suitable security measures,
                    depending on the extent of the risks caused by the proposed
                    processing of your personal data:
                  </p>
                  <ol className='spacing-top'>
                    <li>
                      <span>
                        <b>
                          Availability, integrity, and accessibility of data
                        </b>{' '}
                        on a long-term basis: include but not limited to
                        ensuring appropriate back-ups of personal data are
                        held).
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Company Endeavours:</b> include, but not limited to,
                        policy development and staff trainings; and
                      </span>
                    </li>
                    <li>
                      <span>
                        <b>Technical safeguards:</b> include, but not limited
                        to, physical security of Data, pseudonymization, and
                        encryption).
                      </span>
                    </li>
                  </ol>
                  <p>
                    We have protocols in place to deal with any suspected
                    personal data breach, and where we are legally required to
                    do so, we will notify you and any applicable authority.
                  </p>
                </Anchor>

                <Anchor id='data-retention'>
                  <h2>Data retention</h2>
                  <p>
                    We keep your Personal information for no longer than
                    necessary, and for the purposes for which the information is
                    collected and processed. We consider the amount, nature, and
                    sensitivity of the personal data, the potential risk of harm
                    from unauthorized use or disclosure of your personal data,
                    the purposes for which we process your personal data and
                    whether we can achieve those purposes through other means,
                    and the applicable legal, regulatory, tax, accounting, or
                    other requirements when determining the appropriate
                    retention period for personal data.
                  </p>
                  <p>
                    Here are some examples of the criteria we consider when
                    considering how long we need to keep your personal
                    information:
                  </p>
                  <ul className='spacing-top'>
                    <li>
                      in accordance with relevant industry standards or
                      guidelines;
                    </li>
                    <li>
                      because of our legitimate business necessity to prevent
                      abuse of the promotions we run. To avoid the appearance of
                      abusive behaviour, we shall save a customer's personal
                      data for the duration of the offer and for a specific
                      period after it has ended;
                    </li>
                    <li>
                      if we have a reasonable expectation of litigation or any
                      Alternative Dispute Resolution in connection with our
                      relationship with you, or if we believe we need to keep
                      information to defend possible future legal claims (e.g.,
                      email addresses and content, chats, and letters will be
                      kept for up to 10 years following the end of our
                      relationship, depending on the limitation period
                      applicable in your country);
                    </li>
                    <li>in the event of a complaint;</li>
                    <li>
                      to comply with any applicable legal and/or regulatory
                      requirements with respect to certain types of personal
                      data:
                      <ul>
                        <li>
                          We are required to retain your personal data for a
                          period of 5 years after the end of the relationship
                          between us as a company and you as a customer, under
                          EU anti-money laundering legislation (Anti-Money
                          Laundering Directives); this period may be extended in
                          certain circumstances if so, provided by and in
                          accordance with the applicable legislation; the same
                          is true under UK anti-money laundering legislation.
                        </li>
                        <li>
                          if information is required for auditing and/or other
                          purposes.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Anchor>

                <Anchor id='childrens-privacy'>
                  <h2>Children’s Privacy</h2>
                  <p>
                    Our Site and Services are intended for a broad audience and
                    are not meant for children under eighteen (18) years of age.
                    As a result, in accordance with applicable legal and
                    regulatory duties, we do not intentionally collect, use,
                    solicit, or disclose personal information from children
                    without first obtaining parental consent. We ask that you do
                    not use our Site and Our Services if you are under the age
                    of 18. We may request age-related information from you to
                    comply with applicable legislation.
                  </p>
                  <p>
                    Upon learning of the existence of any Personal Data of
                    persons under the age of 18 that has been collected on our
                    Site without verified parental consent, we will take
                    reasonable actions to remove such Personal Data with
                    immediate effect upon the information coming into
                    Ticketmeta’s notice.
                  </p>
                </Anchor>

                <Anchor id='links-to-other-websites'>
                  <h2>Links to Other Websites</h2>
                  <p>
                    We may include links to sites maintained by third parties
                    other than Ticketmeta ("Third Party Sites"), that we believe
                    may be of interest to you. We only share your Personal Data
                    with these Third-Party Sites if we have a legal basis for
                    doing so.
                  </p>
                  <p>
                    As a result, we cannot guarantee the security or privacy of
                    any information you supply when you visit such sites, and
                    such sites are not subject to this privacy Policy.
                  </p>
                  <p>
                    We do not endorse the Privacy Policies of these Third-Party
                    Sites, and we are not responsible for them. If you choose to
                    visit one of these Third-Party Sites, you should therefore
                    read the privacy Policies on these third-party sites to see
                    how they collect and use your Personal Data.
                  </p>
                </Anchor>

                <Anchor id='cookies-and-other-tracking-technologies'>
                  <h2>Cookies and Other Tracking Technologies</h2>
                  <p>
                    Cookies and other tracking technologies (e.g., web beacons,
                    device identifiers, and pixels) are used by Ticketmeta and
                    our third-party partners, such as our advertising and
                    analytics partners, to offer functionality and to recognize
                    users across multiple Services and devices.
                  </p>
                  <p>
                    We use cookies to track visitor activity on our Site to
                    improve user experience, such as tracking pages viewed, how
                    long someone stays on a webpage, whether error messages were
                    received, remembering information you've provided to provide
                    a more personalized experience, and storing username or
                    related login credentials so you don't have to provide this
                    information every time you visit the Site.
                  </p>
                  <p>
                    The only reason for collecting this data is to understand
                    how our customers and the general public consume our
                    services.
                  </p>
                </Anchor>

                <Anchor id='updates-to-this-privacy-policy'>
                  <h2>Updates to this Privacy Policy</h2>
                  <p>
                    Please keep in mind that we review our privacy practices on
                    a regular basis and that they are subject to change. Return
                    to this page on a regular basis to verify that you are
                    familiar with the most recent version of this Privacy
                    Policy. Any change, update, or modification will go into
                    effect as soon as it is posted on our Site.
                  </p>
                  <p>
                    Any major modification to this Privacy Policy will be
                    communicated to you by posting a notice on the homepage of
                    our Site for a reasonable period following the amendment or
                    by sending an email to the email address connected with your
                    user account, if necessary.
                  </p>
                  <p>
                    We will equally amend the effective date to reflect the
                    latest date of the amendment.
                  </p>
                </Anchor>

                <Anchor id='questions-and-complaints'>
                  <h2>Questions and Complaints</h2>
                  <p>
                    Please contact us at [Enter Email] or mail us using the
                    address below if you have any questions, requests, or
                    complaints regarding how we process your personal
                    information.
                  </p>
                </Anchor>
              </Content>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicyPage;
