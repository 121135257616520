import './benefits.styles.css';
import organizers from 'icons/organizers.svg';
import ticket from 'icons/ticket.svg';
import artist from 'icons/artist.svg';
import Anchor from 'components/ui/Anchor/Anchor';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import cx from 'classnames';

export default function Benefits() {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });

  return (
    <Anchor id='clients'>
      <div
        ref={ref}
        className={cx('benefits', {
          'benefits--animate': entry?.isIntersecting,
        })}
      >
        <div className='container'>
          <div className='row benefits__title-row'>
            <div className='col col-1'></div>
            <div className='col col-11'>
              <h2 className='benefits__title'>
                <span className='benefits__title-text d-block primary--text'>
                  A Game Changer
                </span>
                <span className='benefits__title-text d-block'>
                  for the Event
                </span>
                <span className='benefits__title-text d-block'>Industry</span>
              </h2>
            </div>
          </div>
        </div>

        <div className='benefits__content'>
          <div className='benefits__row'>
            <div className='container'>
              <div className='row'>
                <div className='col col-3'>
                  <h3 className='benefits__subtitle'>
                    <img
                      src={organizers}
                      alt='Organizers'
                      className='benefits__icon'
                      loading='lazy'
                    />
                    Organizers
                  </h3>
                </div>

                <div className='col col-9'>
                  <div className='row'>
                    <div className='col col-7'>
                      <ul className='benefits__text'>
                        <li>SPORTS AND ENTERTAINMENT</li>
                        <li>MUSIC CONCERTS AND FESTIVALS</li>
                      </ul>
                    </div>

                    <div className='col col-5'>
                      <ul className='benefits__text'>
                        <li>MICE</li>
                        <li>WEB3 EVENTS</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='benefits__row'>
            <div className='container'>
              <div className='row'>
                <div className='col col-3'>
                  <h3 className='benefits__subtitle'>
                    <img
                      src={ticket}
                      alt='Venues'
                      className='benefits__icon'
                      loading='lazy'
                    />
                    Venues
                  </h3>
                </div>

                <div className='col col-9'>
                  <div className='row'>
                    <div className='col col-7'>
                      <ul className='benefits__text'>
                        <li>STADIUMS AND ARENAS</li>
                        <li className='benefits__text-item--no-wrap'>
                          EXHIBITIONS AND CONVENTION CENTRES
                        </li>
                      </ul>
                    </div>

                    <div className='col col-5'>
                      <ul className='benefits__text'>
                        <li>CONCERT HALLS AND THEATRES</li>
                        <li>MUSEUMS AND GALLERIES</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='benefits__row'>
            <div className='container'>
              <div className='row'>
                <div className='col col-3'>
                  <h3 className='benefits__subtitle'>
                    <img
                      src={artist}
                      alt='Artists'
                      className='benefits__icon'
                      loading='lazy'
                    />
                    Artists
                  </h3>
                </div>

                <div className='col col-9'>
                  <div className='row'>
                    <div className='col col-7'>
                      <ul className='benefits__text'>
                        <li>PERFORMERS AND DIGITAL ARTISTS</li>
                        <li>MUSIC ARTISTS, POP SINGERS AND BANDS</li>
                      </ul>
                    </div>

                    <div className='col col-5'>
                      <ul className='benefits__text'>
                        <li>MOTIVATIONAL SPEAKERS</li>
                        <li>STAND-UP COMEDIANS</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Anchor>
  );
}
