import { Button } from 'react-bootstrap';
import cx from 'classnames';
import { useLayoutContext } from '../layout-context';
import css from './menu-button.module.scss';

export const MenuButton = () => {
  const layoutContext = useLayoutContext();

  return (
    <Button
      className={cx(css.root, { [css.open]: layoutContext.isNavigationOpened })}
      onClick={layoutContext.toggleNavigation}
      variant='link'
      aria-label={
        layoutContext.isNavigationOpened
          ? 'Close navigation'
          : 'Open navigation'
      }
    >
      <span className={css.icon}>
        <span />
        <span />
        <span />
        <span />
      </span>
    </Button>
  );
};
