import Logo from '../Logo/Logo';
import Button from 'components/form/Button/Button';
import FooterMenu from '../FooterMenu/FooterMenu';
import Anchor from 'components/ui/Anchor/Anchor';
import { showDemoModal } from 'components/demo-modal/DemoModal';
import './footer.styles.css';

export default function Footer() {
  return (
    <Anchor id='contact'>
      <footer className='container footer'>
        <div className='row justify-between align-end'>
          <div className='col col-auto'>
            <Logo />

            <div className='footer__copyright mt-2'>ticketmeta.io © 2024</div>
          </div>

          <div className='col text-right'>
            <div className='footer__right'>
              <div>
                <Button
                  text='GET DEMO'
                  color='secondary'
                  className='footer__button mb-4'
                  onClick={showDemoModal}
                />
              </div>

              <FooterMenu />
            </div>
          </div>
        </div>
      </footer>
    </Anchor>
  );
}
