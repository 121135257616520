import React, { useCallback, useEffect } from 'react';
import MainMenu from '../MainMenu/MainMenu';
import css from './header-menu.module.scss';
import { useLayoutContext } from '../layout-context';
import { Modal } from 'react-bootstrap';
import { useMediaQuery } from '@uidotdev/usehooks';

export const HeaderMenu = () => {
  const layoutContext = useLayoutContext();
  const isMobile = useMediaQuery('screen and (max-width: 844px)');

  useEffect(() => {
    if (!isMobile) {
      layoutContext.closeNavigation();
    }
  }, [isMobile]);

  const onClickHandler = () => {
    layoutContext.closeNavigation();
  };

  return (
    <Modal
      className={css.root}
      show={layoutContext.isNavigationOpened}
      onHide={layoutContext.closeNavigation}
      backdrop={false}
      aria-label='Navigation'
      fullscreen
      scrollable
    >
      <Modal.Body onClick={onClickHandler}>
        <MainMenu />
      </Modal.Body>
    </Modal>
  );
};
