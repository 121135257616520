import React from 'react';
import './bootstrap.scss';
import './index.scss';
import 'react-aspect-ratio/aspect-ratio.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BaseLayout from 'layouts/BaseLayout';
import Home from 'pages/Home/Home';
import FAQ from 'pages/FAQ/FAQ';
import NiceModal from '@ebay/nice-modal-react';
import lax from 'libs/lax.js';
import { getScrollPosition } from 'utils/getScrollPosition';
import { LayoutContextProvider } from 'components/layout/layout-context';
import Contacts from 'pages/Contacts/Contacts';
import CookiePolicyPage from 'pages/CookiePolicy/CookiePolicy';
import PrivacyPolicyPage from 'pages/PrivacyPolicy/PrivacyPolicy';

export default function App() {
  return (
    <LayoutContextProvider>
      <NiceModal.Provider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<BaseLayout />}>
              <Route index element={<Home />} />
              <Route path='faq' element={<FAQ />} />
              <Route path='contacts' element={<Contacts />} />
              <Route path='cookies-policy' element={<CookiePolicyPage />} />
              <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
              {/* <Route path="contact" element={<Contact />} />
          <Route path="*" element={<NoPage />} /> */}
            </Route>
          </Routes>
        </BrowserRouter>
      </NiceModal.Provider>
    </LayoutContextProvider>
  );
}

if (typeof window !== 'undefined') {
  lax.init();

  lax.addDriver(
    'scrollY',
    function () {
      return getScrollPosition()[0];
    },
    { inertiaEnabled: true }
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
