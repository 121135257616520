import * as yup from 'yup';
import { api } from './api';

export const sendGetDemoForm = (values) => {
  return api({
    url: '/forms/landing',
    method: 'post',
    data: values,
  });
};

export const sentGetDemoValidationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  company: yup.string().required('Company is a required field'),
  email: yup
    .string()
    .email('Field must be a valid email')
    .required('Email is a required field'),
  phone: yup.string(),
  case: yup.string(),
});

export const sentGetDemoInitialValues = {
  name: '',
  company: '',
  email: '',
  phone: '',
  case: '',
};
