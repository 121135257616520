import * as yup from 'yup';
import { api } from './api';

export const sendAskQuestionForm = (values) => {
  return api({
    url: '/forms/qa',
    method: 'post',
    data: values,
  });
};

export const sentAskQuestionValidationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  email: yup
    .string()
    .email('Field must be a valid email')
    .required('Email is a required field'),
  question: yup.string(),
});

export const sentAskQuestionInitialValues = {
  name: '',
  email: '',
  question: '',
};
