import Logo from '../Logo/Logo';
import Button from 'components/form/Button/Button';
import './header.styles.css';
import { showDemoModal } from 'components/demo-modal/DemoModal';
import { MenuButton } from './MenuButton';
import MainMenu from '../MainMenu/MainMenu';

export default function Header() {
  return (
    <header className='container header'>
      <div className='row justify-between align-center'>
        <div className='col col-auto header__logo'>
          <Logo />
        </div>

        <div className='col col-auto'>
          <div className='d-flex align-center'>
            <div className='mr-9 header__main-menu'>
              <MainMenu />
            </div>

            <Button
              text='GET DEMO'
              color='secondary'
              onClick={showDemoModal}
              size='xs'
            />

            <div className='header__main-menu-button'>
              <MenuButton />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
