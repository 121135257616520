import Button from 'components/form/Button/Button';
import arrow from 'icons/arrow-right.svg';
import { showDemoModal } from 'components/demo-modal/DemoModal';
import { useThanosAnimation } from './useThanosAnimation';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { useRef, useState } from 'react';
import cx from 'classnames';
import './cta.styles.css';

const Animation = ({ containerRef, onError }) => {
  const canvasRef = useRef();

  useThanosAnimation({ canvasRef, containerRef, onError });

  return (
    <canvas
      ref={canvasRef}
      role='heading'
      aria-level={3}
      aria-label='Go Phygital with TicketMeta'
      className='cta__canvas'
    />
  );
};

export default function CTA() {
  const containerRef = useRef();
  const [error, setError] = useState(null);
  const hasError = !!error;
  const [ref, entry] = useIntersectionObserver({
    threshold: 0.2,
    root: null,
    rootMargin: '0px',
  });

  return (
    <div
      ref={containerRef}
      className={cx('cta-wrapper', {
        'cta-wrapper--canvas-error': !!hasError,
      })}
    >
      {entry?.isIntersecting && !hasError && (
        <Animation containerRef={containerRef} onError={setError} />
      )}
      <div ref={ref} className='cta container'>
        <div className='cta__in'>
          <h3 className='cta__title'>Go Phygital with TicketMeta</h3>

          <Button
            text='GET DEMO'
            size='l'
            onClick={showDemoModal}
            icon={arrow}
          />
        </div>
      </div>
    </div>
  );
}
