import { useEffect, useState } from 'react';
import { AspectRatio } from 'react-aspect-ratio';
import cx from 'classnames';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import feature from 'icons/feature.svg';
import Anchor from 'components/ui/Anchor/Anchor';
import { LottieAnimation } from 'components/ui/LottieAnimation/LottieAnimation';
import Benefits from '../Benefits/Benefits';
import './features.styles.css';

const AnimatedVideo = ({ sources, poster, onEnded }) => {
  return (
    <video onEnded={onEnded} poster={poster} autoPlay muted playsInline>
      {sources.map((item) => (
        <source key={item.src} {...item} />
      ))}
    </video>
  );
};

const animations = [
  {
    videoUrl: [
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Insightful+Analytics.mp4',
        type: 'video/mp4',
        media: 'all and (max-width:1080px)',
      },
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Insightful+Analytics.mp4',
        type: 'video/mp4',
      },
    ],
    poster: '/videos/insightful_analytics.jpg',
    Content: ({ isAnimated, videoProps, animateBlock }) => (
      <>
        <h3 className='features__subtitle primary--text'>
          <LottieAnimation
            className='features__animated-icon'
            path='/icons/lottie/insightful.json'
            play={isAnimated}
            fallback={
              <svg viewBox='0 0 128 128'>
                <defs>
                  <clipPath id='a'>
                    <path d='M0 0h128v128H0z' />
                  </clipPath>
                </defs>
                <g clipPath='url(#a)'>
                  <path
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='M-17-41h34c13.255 0 24 10.745 24 24v34c0 13.255-10.745 24-24 24h-34c-13.255 0-24-10.745-24-24v-34c0-13.255 10.745-24 24-24z'
                    fill='none'
                    transform='translate(64 64)'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='M3.5 3.5V30'
                    fill='none'
                    transform='translate(40.5 41.5)'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='M3.5 3.5v38'
                    fill='none'
                    transform='translate(60.5 41.5)'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='M3.5 3.5V19'
                    fill='none'
                    transform='translate(80.5 41.5)'
                  />
                </g>
              </svg>
            }
          />

          <span>Insightful Analytics</span>
        </h3>
        <p className='features__text'>
          Access comprehensive sales and
          <br /> revenue statistics to power data-driven
          <br /> marketing initiatives for optimal
          <br /> audience engagement and strategic
          <br /> growth
        </p>
        <div className='features__image-wrapper'>
          <div className='features__image'>
            <AspectRatio ratio={1184 / 572}>
              {videoProps.sources && (
                <AnimatedVideo key={animateBlock} {...videoProps} />
              )}
            </AspectRatio>
          </div>
        </div>
      </>
    ),
  },
  {
    videoUrl: [
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Exclusive+Rewards.mp4',
        type: 'video/mp4',
        media: 'all and (max-width:1080px)',
      },
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Exclusive+Rewards.mp4',
        type: 'video/mp4',
      },
    ],
    poster: '/videos/exclusive_rewards.jpg',
    Content: ({ isAnimated }) => (
      <>
        <h3 className='features__subtitle'>
          <LottieAnimation
            className='features__animated-icon'
            path='/icons/lottie/exclusive-rewards.json'
            play={isAnimated}
            fallback={
              <svg viewBox='0 0 128 128'>
                <defs>
                  <clipPath id='a'>
                    <path d='M0 0h128v128H0z' />
                  </clipPath>
                </defs>
                <g clipPath='url(#a)'>
                  <path
                    fill='none'
                    stroke='#1F1F1F'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='7'
                    d='M104.597 32.134c-2.345-2.579-5.13-4.625-8.196-6.021A23.31 23.31 0 0 0 86.732 24c-3.318 0-6.603.718-9.669 2.113-3.065 1.396-5.85 3.442-8.196 6.021a6.582 6.582 0 0 1-9.736 0c-4.739-5.206-11.165-8.131-17.866-8.131-6.701 0-13.127 2.925-17.865 8.131C18.662 37.34 16 44.401 16 51.763c0 7.363 2.662 14.424 7.4 19.63l4.868 5.349 17.981 19.756c9.521 10.462 25.978 10.462 35.499 0l17.981-19.756 4.868-5.349c2.347-2.577 4.209-5.639 5.479-9.007A30.146 30.146 0 0 0 112 51.763c0-3.645-.654-7.256-1.924-10.625-1.27-3.368-3.132-6.427-5.479-9.004z'
                    display='block'
                  />
                </g>
              </svg>
            }
          />
          Exclusive Rewards
        </h3>
        <p className='features__text features__text--opacity'>
          Transform your ticketing experience
          <br /> with innovative marketing strategies,
          <br /> phygital loyalty rewards, and immersive
          <br /> virtual and augmented realities, all on
          <br /> one platform
        </p>
      </>
    ),
  },
  {
    videoUrl: [
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Innovative+Seat+Picker.mp4',
        type: 'video/mp4',
        media: 'all and (max-width:1080px)',
      },
      {
        src: 'https://ticketmeta-images.s3.amazonaws.com/Innovative+Seat+Picker.mp4',
        type: 'video/mp4',
      },
    ],
    poster: '/videos/innovative_seat_picker.jpg',
    Content: ({ isAnimated }) => (
      <>
        <h3 className='features__subtitle'>
          <LottieAnimation
            className='features__animated-icon'
            path='/icons/lottie/innovative-seat-picker.json'
            play={isAnimated}
            fallback={
              <svg viewBox='0 0 128 128'>
                <defs>
                  <clipPath id='a'>
                    <path d='M0 0h128v128H0z' />
                  </clipPath>
                </defs>
                <g clipPath='url(#a)'>
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='m38.423 82 1.778 8.066c1.427 6.472-2.663 12.875-9.135 14.302L23 106.146M38.423 46.858l1.778-8.066c1.427-6.472-2.663-12.875-9.135-14.302L23 22.712'
                    fill='none'
                    display='block'
                  />
                  <path
                    fill='#1F1F1F'
                    d='M36.82 101.091a3.5 3.5 0 0 0 2.788-6.42l-2.789 6.42zm1.27-74.824a40.51 40.51 0 0 0-18.427 14.606l5.754 3.99a33.498 33.498 0 0 1 15.24-12.084l-2.567-6.512zM19.663 40.873a40.508 40.508 0 0 0-7.207 22.382l7 .12a33.501 33.501 0 0 1 5.961-18.513l-5.754-3.989zm-7.207 22.382A40.5 40.5 0 0 0 18.9 85.87l5.884-3.79a33.497 33.497 0 0 1-5.328-18.704l-7-.12zM18.9 85.87a40.501 40.501 0 0 0 17.92 15.222l2.787-6.42a33.503 33.503 0 0 1-14.823-12.593L18.9 85.87z'
                    display='block'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    stroke='#1F1F1F'
                    strokeWidth='7'
                    d='m110.838 61.736-3.015-7.948c-1.959-5.164-7.732-7.762-12.896-5.803l-8.415 3.193a8 8 0 0 1-10.318-4.643l-4.522-11.922a7.25 7.25 0 0 0-13.557 5.143l9.566 25.219c.653 1.72-1.246 3.487-2.988 2.887-5.376-1.848-13.427-3.222-14.501 4.579-1.087 7.9 12.805 12.732 22.185 15.075 5.367 1.341 10.97.691 16.14-1.271l13.616-5.164c7.746-2.939 11.643-11.6 8.705-19.345z'
                    fill='none'
                    display='block'
                  />
                </g>
              </svg>
            }
          />
          Innovative Seat Picker
        </h3>
        <p className='features__text features__text--opacity'>
          Leverage our state-of-the-art seating
          <br /> chart technology to offer users a clear,
          <br /> visually appealing view of the venue
          <br />
          layout for easy seat selection and
          <br /> pricing.
        </p>
      </>
    ),
  },
];

const INTERACTION_TYPE = {
  AUTOPLAY: 'AUTOPLAY',
  HOVER: 'HOVER',
};

const ANIMATION_ORDER = [1, 0, 2];

export default function Features() {
  const [animateBlock, setAnimateBlock] = useState(null);
  const [interationType, setInteractionType] = useState(
    INTERACTION_TYPE.AUTOPLAY
  );

  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  const [mobileRef, mobileEntry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });

  useEffect(() => {
    if (entry?.isIntersecting || mobileEntry?.isIntersecting) {
      setAnimateBlock(0);
    } else {
      setAnimateBlock(null);
    }
  }, [entry?.isIntersecting, mobileEntry?.isIntersecting]);

  const getAnimationIndex = (orderIndex) => {
    return ANIMATION_ORDER[orderIndex];
  };
  const currentAnimationIndex = getAnimationIndex(animateBlock);
  const currentAnimation = animations[currentAnimationIndex];
  const onAnimationAndedHandler = (event) => {
    if (interationType === INTERACTION_TYPE.AUTOPLAY) {
      setAnimateBlock((animateBlock) => {
        const nextAnimation = animateBlock + 1;

        return nextAnimation % animations.length;
      });
    }

    if (interationType === INTERACTION_TYPE.HOVER) {
      event.target?.play?.();
    }
  };

  const onMouseEnter = (animationName) => () => {
    setInteractionType(INTERACTION_TYPE.HOVER);
    setAnimateBlock(animationName);
  };

  const onMouseLeave = (animationName) => () => {
    setInteractionType(INTERACTION_TYPE.AUTOPLAY);
  };

  const videoProps = {
    sources: currentAnimation?.videoUrl,
    poster: currentAnimation?.poster,
    onEnded: onAnimationAndedHandler,
  };

  return (
    <Anchor id='features'>
      <div className='features'>
        <div className='features__container'>
          <div className='container'>
            <h2 className='features__title'>
              <img
                className='features__title-icon'
                loading='lazy'
                src={feature}
                alt=''
                aria-hidden
              />
              <span className='d-block primary--text'>The Power-packed</span>
              Features of TicketMeta
            </h2>

            <div className='features__content' ref={mobileRef}>
              <div className='features__content-in'>
                <div className='row justify-between'>
                  {animations.map(({ Content }, idx) => {
                    const isItemAnimated = currentAnimationIndex === idx;
                    const animationIndex = getAnimationIndex(idx);
                    return (
                      <div
                        key={animationIndex}
                        className={cx('features__item col col-4', {
                          'features__item--animate': isItemAnimated,
                        })}
                        onMouseEnter={onMouseEnter(animationIndex)}
                        onMouseLeave={onMouseLeave(animationIndex)}
                      >
                        <Content
                          isAnimated={isItemAnimated}
                          videoProps={videoProps}
                          animateBlock={currentAnimationIndex}
                        />
                      </div>
                    );
                  })}
                </div>

                <div className='features__footer'>
                  <div className='features__image' ref={ref}>
                    <AspectRatio ratio={1184 / 572}>
                      {currentAnimation && (
                        <AnimatedVideo
                          key={currentAnimationIndex}
                          {...videoProps}
                        />
                      )}
                    </AspectRatio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Benefits />
      </div>
    </Anchor>
  );
}
