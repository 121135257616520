import React from 'react';
import { useFormikContext } from 'formik';
import cx from 'classnames';
import Button from '../Button/Button';
import { Spinner } from 'react-bootstrap';
import css from './SubmitButton.module.scss';

export const SubmitButton = ({
  color = 'secondary',
  className,
  children,
  props,
}) => {
  const formik = useFormikContext();
  const isSubmitting = !!formik.isSubmitting;

  return (
    <Button
      color={color}
      className={cx(css.root, className)}
      type='submit'
      {...props}
      text={
        <>
          <span
            className={cx({
              [css.isLoadingText]: isSubmitting,
            })}
          >
            {children}
          </span>
          {isSubmitting && (
            <span className={css.loader}>
              <Spinner animation='border' variant='primary' size='sm' />
            </span>
          )}
        </>
      }
    />
  );
};
