import { HashLink as Link } from 'react-router-hash-link';
import items from './items';
import './main-menu.styles.scss';

export default function MainMenu() {
  return (
    <nav className='main-menu'>
      <ul className='main-menu__list'>
        {items.map((item) => (
          <li key={item.name} className='main-menu__item'>
            <Link to={item.to} className='main-menu__link'>
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
