import './tile.styles.css';

export default function Tile({
  title = '',
  text = '',
  image,
  variant = 'a',
  children,
}) {
  const img =
    typeof image === 'string' ? (
      <img className='tile__image' loading='lazy' src={image} alt={title} />
    ) : (
      image
    );

  const className = ['tile', `tile--variant-${variant}`].join(' ');
  const tileInClassName = variant === 'g' ? 'tile__surface' : 'tile__in';

  const computedTitle = title ? (
    <header className='tile__header'>
      <h3 className='tile__title'>{title}</h3>
    </header>
  ) : null;

  const computedText = text ? <p className='tile__text'>{text}</p> : null;

  return (
    <div className={className}>
      <div className={tileInClassName}>
        {computedTitle}

        {computedText}

        {img}

        {children}
      </div>
    </div>
  );
}
