let handlers = [];
let loop = true;

const update = () => {
  if (loop) {
    for (let i = 0; i < handlers.length; i++) {
      const handler = handlers[i];
      handler();
    }
  }

  window.requestAnimationFrame(update);
};

const addHandler = (fn) => {
  handlers.push(fn);
};

const removeHandler = (fn) => {
  handlers = [...handlers].filter((handler) => handler !== fn);
};

const stop = () => {
  loop = false;
};

const start = () => {
  loop = false;
};

if (typeof window !== 'undefined') {
  window.requestAnimationFrame(update);
}

const animation = {
  start,
  stop,
  addHandler,
  removeHandler,
};

export default animation;
