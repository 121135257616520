import './button.styles.css';
import cx from 'classnames';

export default function Button({
  text,
  color = 'primary',
  type = 'button',
  size = 's',
  icon,
  onClick,
  className,
}) {
  const buttonClassName = cx(
    'button',
    `button--${color}`,
    `button--size-${size}`,
    className
  );

  const appendIcon = icon ? (
    <img className='button__append' src={icon} aria-hidden />
  ) : null;

  return (
    <button type={type} className={buttonClassName} onClick={onClick}>
      <span className='button__border button__border-outer'>
        <span className='button__border-gradient'></span>
      </span>
      <span className='button__border button__border-inner'>
        <span className='button__border-gradient'></span>
      </span>

      <span className='button__content'>
        <span className='button__content-in'>
          <span>{text}</span>

          {appendIcon}
        </span>
      </span>
    </button>
  );
}
