import Tile from 'components/ui/Tile/Tile';
import './discover.styles.css';
import profits from 'images/profits.svg';
import wizard from 'images/digital-ticket.svg';
import payment from 'images/payment.svg';
import paymentMobile from 'images/payment-mobile.svg';
import solutionMobile from 'images/solution-mobile.svg';
import solution from 'images/solution.svg';
import Anchor from 'components/ui/Anchor/Anchor';
import { useEffect } from 'react';
import lax from 'libs/lax.js';
import { AspectRatio } from 'react-aspect-ratio';

export default function Discover() {
  useEffect(() => {
    lax.addElements(
      '.discover__item',
      {
        scrollY: {
          translateY: [
            ['elInY', `elCenterY`],

            {
              799: ['50', 0],
              800: ['150', 0],
            },
            {
              inertia: 0,
              easing: 'easeInOutQuad',
            },
          ],
          opacity: [
            ['elInY', 'elCenterY-elHeight/3'],
            [0, 1],
            {
              inertia: 0,
            },
          ],
        },
      },
      {}
    );

    lax.addElements(
      '.discover',
      {
        scrollY: {
          translateY: [
            ['elInY', 'elCenterY', `elOutY`],
            {
              799: ['0', '40', '0'],
              800: ['0', '-80', '0'],
            },
            {
              inertia: 0,
            },
          ],
        },
      },
      {}
    );

    return () => {
      lax.removeElements('.discover__item');
      lax.removeElements('.discover');
    };
  }, []);

  return (
    <Anchor id='benefits'>
      <div className='discover'>
        <div className='container'>
          <h2 className='discover__title'>
            <span className='d-block'>Discover how TicketMeta</span>
            <span className='primary--text'>
              {' '}
              can
              <br className='discover__title-brake--mobile' /> make a difference
            </span>
          </h2>

          <div className='discover__content'>
            <div className='discover__item'>
              <Tile
                title='Secondary Market Revenue'
                text={
                  <>
                    Implement secondary market control using smart contracts
                    <br /> to earn a percentage of each ticket resale on
                    secondary markets.
                  </>
                }
                image={
                  <AspectRatio ratio={541 / 352}>
                    <img
                      loading='lazy'
                      src={profits}
                      width={541}
                      height={352}
                      alt={'Secondary Market Revenue'}
                    />
                  </AspectRatio>
                }
                variant='a'
              />
            </div>

            <div className='discover__item'>
              <Tile
                title='Digital Ticket Wizardy'
                text={
                  <>
                    Manage your ticket's entire lifecycle effortlessly through
                    <br /> programmable digital tickets. Tailor pricing, resale
                    policies,
                    <br /> and shop configurations with discounts and dynamic
                    pricing
                    <br /> at your fingertips.
                  </>
                }
                image={
                  <AspectRatio ratio={541 / 365}>
                    <img
                      loading='lazy'
                      src={wizard}
                      width={541}
                      height={365}
                      alt={'Digital Ticket Wizardy'}
                    />
                  </AspectRatio>
                }
                variant='b'
              />
            </div>

            <div className='discover__item'>
              <Tile
                title='Effortless Payments'
                text='Simplify payment processes with specially designed smart contracts, providing you with the flexibility of choosing between cryptocurrency and traditional fiat currency.'
                image={
                  <>
                    <AspectRatio
                      ratio={541 / 536}
                      className='discover__item-image-desktop'
                    >
                      <img
                        src={payment}
                        alt={'Effortless Payments'}
                        width={541}
                        height={536}
                        loading='lazy'
                      />
                    </AspectRatio>

                    <AspectRatio
                      ratio={297 / 201}
                      className='discover__item-image-mobile'
                    >
                      <img
                        src={paymentMobile}
                        alt={'Effortless Payments'}
                        width={297}
                        height={201}
                        loading='lazy'
                      />
                    </AspectRatio>
                  </>
                }
                variant='c'
              />
            </div>

            <div className='discover__item'>
              <Tile
                title='Сost Effective Solution'
                text={
                  <>
                    Decrease your costs per one sold ticket up to 3 times and
                    <br /> get full control of the tickets distribution
                    comparing with the current ticketing systems.
                  </>
                }
                image={
                  <>
                    <AspectRatio
                      ratio={541 / 302}
                      className='discover__item-image-desktop'
                    >
                      <img
                        src={solution}
                        alt={'Сost Effective Solution'}
                        width={541}
                        height={302}
                        loading='lazy'
                      />
                    </AspectRatio>
                    <AspectRatio
                      ratio={297 / 166}
                      className='discover__item-image-mobile'
                    >
                      <img
                        src={solutionMobile}
                        alt={'Сost Effective Solution'}
                        width={297}
                        height={166}
                        loading='lazy'
                      />
                    </AspectRatio>
                  </>
                }
                variant='d'
              />
            </div>
          </div>
        </div>
      </div>
    </Anchor>
  );
}
