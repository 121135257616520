import React from 'react';
import cx from 'classnames';
import css from './Card.module.scss';

export const Card = ({ children, className, variant = 'cards' }) => {
  return (
    <div
      className={cx(
        css.root,
        { [css[`variant-${variant}`]]: !!css[`variant-${variant}`] },
        className
      )}
    >
      {variant === 'cards' && (
        <>
          <div className={css.backCard2}>
            <div></div>
          </div>
          <div className={css.backCard1} />
        </>
      )}
      <div className={css.gradientBorder} />
      <div className={css.content}>{children}</div>
    </div>
  );
};
