import { Accordion } from 'react-bootstrap';
import { HashLink as Link } from 'react-router-hash-link';
import { Scrollspy } from '@makotot/ghostui';
import cx from 'classnames';
import css from './nav-list.module.scss';
import { createRef, useEffect, useLayoutEffect, useState } from 'react';

const COOKIES_LINKS = [
  {
    href: '/cookies-policy',
    id: 'terms-of-use',
    children: 'Terms of Use',
  },
  {
    href: '/cookies-policy',
    id: 'whats-is-a-cookie',
    children: 'What is a Cookie?',
  },
  {
    href: '/cookies-policy',
    id: 'сookie-categories-according-to-its-purpose',
    children: 'Cookie categories according to its purpose',
  },
  {
    href: '/cookies-policy',
    id: 'сookie-categories-according-to-the-time-they-remain-activated',
    children: 'Cookie categories according to the time they remain activated',
  },
  {
    href: '/cookies-policy',
    id: 'how-to-configure-or-reject-cookies',
    children: 'How to configure or reject cookies?',
  },
  {
    href: '/cookies-policy',
    id: 'how-long-do-cookies-last-on-your-computer',
    children: 'How long do cookies last on your computer?',
  },
  {
    href: '/cookies-policy',
    id: 'local-storage-policy',
    children: 'Local storage policy',
  },
];

const PRIVACY_LINKS = [
  {
    href: '/privacy-policy',
    id: 'introduction',
    children: 'Introduction',
  },
  {
    href: '/privacy-policy',
    id: 'the-aim-of-this-privacy-policy',
    children: 'The aim of this privacy policy',
  },
  {
    href: '/privacy-policy',
    id: 'the-data-we-collect-about-you',
    children: 'The data we collect about you',
  },
  {
    href: '/privacy-policy',
    id: 'what-you-agree-to-by-using-our-site',
    children: 'What You Agree to By Using Our Site',
  },
  {
    href: '/privacy-policy',
    id: 'how-we-collect-your-data',
    children: 'How We Collect Your Data',
  },
  {
    href: '/privacy-policy',
    id: 'how-we-use-your-data',
    children: 'How We Use Your Data',
  },
  {
    href: '/privacy-policy',
    id: 'international-privacy-practices',
    children: 'International Privacy Practices',
  },
  {
    href: '/privacy-policy',
    id: 'confidentiality-and-security-of-personal-data',
    children: 'Confidentiality and Security of Personal Data',
  },
  {
    href: '/privacy-policy',
    id: 'data-retention',
    children: 'Data retention',
  },
  {
    href: '/privacy-policy',
    id: 'childrens-privacy',
    children: 'Children’s Privacy',
  },
  {
    href: '/privacy-policy',
    id: 'links-to-other-websites',
    children: 'Links to Other Websites',
  },
  {
    href: '/privacy-policy',
    id: 'cookies-and-other-tracking-technologies',
    children: 'Cookies and Other Tracking Technologies',
  },
  {
    href: '/privacy-policy',
    id: 'updates-to-this-privacy-policy',
    children: 'Updates to this Privacy Policy',
  },
  {
    href: '/privacy-policy',
    id: 'questions-and-complaints',
    children: 'Questions and Complaints',
  },
];

export const PoliciesNavList = ({ containerSelector, defaultActiveKey }) => {
  const [cookiesSectionRefs, setCookiesSectionRefs] = useState([]);
  const [privacySectionRefs, setPrivacySectionRefs] = useState([]);

  useLayoutEffect(() => {
    const cookiesRefs = COOKIES_LINKS.map((item) => {
      const ref = createRef();

      ref.current = document.querySelector(`#${item.id}`);

      return ref;
    });

    setCookiesSectionRefs(cookiesRefs);

    const privacyRefs = PRIVACY_LINKS.map((item) => {
      const ref = createRef();

      ref.current = document.querySelector(`#${item.id}`);

      return ref;
    });

    setPrivacySectionRefs(privacyRefs);

    return () => {
      setCookiesSectionRefs([]);
      setCookiesSectionRefs([]);
    };
  }, [containerSelector]);

  return (
    <nav className={css.root}>
      <Accordion defaultActiveKey={defaultActiveKey}>
        <Accordion.Item eventKey='cookie'>
          <Accordion.Header className='heading--primary'>
            Cookies
          </Accordion.Header>
          <Accordion.Body>
            <Scrollspy sectionRefs={cookiesSectionRefs}>
              {({ currentElementIndexInViewport }) => {
                return (
                  <ul>
                    {COOKIES_LINKS.map((item, index) => (
                      <li
                        key={item.id}
                        className={cx({
                          [css.active]:
                            defaultActiveKey === 'cookie'
                              ? currentElementIndexInViewport === index
                              : false,
                        })}
                      >
                        <Link to={[item.href, '#', item.id].join('')}>
                          {item.children}
                        </Link>
                      </li>
                    ))}
                  </ul>
                );
              }}
            </Scrollspy>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='privacy'>
          <Accordion.Header className='heading--primary'>
            Privacy
          </Accordion.Header>
          <Accordion.Body>
            <Scrollspy sectionRefs={privacySectionRefs}>
              {({ currentElementIndexInViewport }) => {
                return (
                  <ul>
                    {PRIVACY_LINKS.map((item, index) => (
                      <li
                        key={item.id}
                        className={cx({
                          [css.active]:
                            defaultActiveKey === 'privacy'
                              ? currentElementIndexInViewport === index
                              : false,
                        })}
                      >
                        <Link to={[item.href, '#', item.id].join('')}>
                          {item.children}
                        </Link>
                      </li>
                    ))}
                  </ul>
                );
              }}
            </Scrollspy>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </nav>
  );
};
