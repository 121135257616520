import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';
import './logo.styles.css';

export default function Logo() {
  return (
    <Link to='/' aria-label='Ticketmeta home'>
      <LogoIcon />
    </Link>
  );
}
