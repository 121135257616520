import React from 'react';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import {
  sendGetDemoForm,
  sentGetDemoInitialValues,
  sentGetDemoValidationSchema,
} from 'api/sendGetDemoForm';
import css from './DemoForm.module.scss';
import { SubmitButton } from 'components/form/SubmitButton/SubmitButton';

export const DemoForm = ({ onSubmit }) => {
  const onSubmitHandler = async (values) => {
    try {
      const res = await sendGetDemoForm(values);
      onSubmit(res, values, null);
    } catch (error) {
      onSubmit(null, values, error);
    }
  };

  return (
    <Formik
      validationSchema={sentGetDemoValidationSchema}
      onSubmit={onSubmitHandler}
      initialValues={sentGetDemoInitialValues}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        isSubmitting,
        submitCount,
        errors,
      }) => {
        const isSubmited = !!submitCount;

        return (
          <Form noValidate onSubmit={handleSubmit} className={css.form}>
            <Form.Group
              className={css.formGroup}
              controlId='validationFormikName'
            >
              <Form.Label>
                Name<span className='label-required'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='e.g San Andreas'
                name='name'
                value={values.name}
                onChange={handleChange}
                isInvalid={isSubmited && !!errors.name}
              />

              <Form.Control.Feedback type='invalid'>
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={css.formGroup}
              controlId='validationFormikCompany'
            >
              <Form.Label>
                Company name<span className='label-required'>*</span>
              </Form.Label>
              <Form.Control
                type='text'
                placeholder='enter your company name'
                name='company'
                value={values.company}
                onChange={handleChange}
                isInvalid={isSubmited && !!errors.company}
              />

              <Form.Control.Feedback type='invalid'>
                {errors.company}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={css.formGroup}
              controlId='validationFormikEmail'
            >
              <Form.Label>
                Email<span className='label-required'>*</span>
              </Form.Label>
              <Form.Control
                type='email'
                placeholder='e.g sanandreas@gmail.com'
                name='email'
                value={values.email}
                onChange={handleChange}
                isInvalid={isSubmited && !!errors.email}
              />

              <Form.Control.Feedback type='invalid'>
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={css.formGroup}
              controlId='validationFormikPhone'
            >
              <Form.Label>Mobile phone number (optional)</Form.Label>
              <Form.Control
                type='tel'
                placeholder='enter your mobile phone number'
                name='phone'
                value={values.phone}
                onChange={handleChange}
                isInvalid={isSubmited && !!errors.phone}
              />

              <Form.Control.Feedback type='invalid'>
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className={css.formGroup}
              md='6'
              controlId='validationFormikCase'
            >
              <Form.Label>Your case (optional)</Form.Label>
              <Form.Control
                type='text'
                placeholder='describe your ploblem here'
                name='case'
                as='textarea'
                value={values.case}
                onChange={handleChange}
                isInvalid={isSubmited && !!errors.case}
                className={css.case}
              />

              <Form.Control.Feedback type='invalid'>
                {errors.case}
              </Form.Control.Feedback>
            </Form.Group>

            <SubmitButton className={css.submitButton}>GET DEMO</SubmitButton>
          </Form>
        );
      }}
    </Formik>
  );
};
