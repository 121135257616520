import lottie from 'lottie-web';
import { useCallback, useEffect, useRef, useState } from 'react';
import './LottieAnimation.scss';

export const LottieAnimation = ({ className, path, fallback, play }) => {
  const [state, setState] = useState({ loaded: false });
  const containerRef = useRef(null);
  const animRef = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path,
    });

    const onDataReadyHandler = () => {
      setState((state) => ({
        ...state,
        loaded: true,
      }));
    };

    anim.addEventListener('data_ready', onDataReadyHandler);

    animRef.current = anim;

    return () => {
      anim.destroy();
      animRef.current = null;
    };
  }, [path]);

  useEffect(() => {
    const anim = animRef.current;

    if (!anim) {
      return;
    }

    if (play) {
      anim.loop = true;
      anim.play();
    } else {
      anim.loop = false;
    }
  }, [play]);

  const setRef = useCallback((element) => {
    containerRef.current = element;
  }, []);

  return (
    <div className={className}>
      <div
        ref={setRef}
        className='lottie-animation__icon'
        style={{ display: state.loaded ? null : 'none' }}
      />
      {fallback && !state.loaded && (
        <div className='lottie-animation__fallback'>{fallback}</div>
      )}
    </div>
  );
};
