import React, { useRef } from 'react';
import cx from 'classnames';
import Sticky from 'react-sticky-el/lib/basic-version';
import { SubmitButton } from 'components/form/SubmitButton/SubmitButton';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Card } from 'components/ui/Card/Card';
import {
  showFailureModal,
  showSuccessModal,
} from 'components/demo-modal/DemoModal';
import {
  sendAskQuestionForm,
  sentAskQuestionValidationSchema,
  sentAskQuestionInitialValues,
} from 'api/sendAskQuestionForm';
import css from './faq.module.scss';

const FAQ = () => {
  const onSubmitHandler = async (values, formik) => {
    try {
      await sendAskQuestionForm(values);
      formik.resetForm(sentAskQuestionInitialValues);
      showSuccessModal({
        children: (
          <>
            Thanks for reaching out!
            <br />
            We're on it and will circle back to you soon.
          </>
        ),
      });
    } catch (error) {
      console.log(error);
      showFailureModal();
    }
  };

  return (
    <section className={css.root}>
      <div className='container'>
        <header className={css.header}>
          <h1 className={cx(css.heading, 'heading--primary')}>
            Freequently asked questions
          </h1>
          <p className='subtitle'>
            If you can't find what you're looking for in our FAQ, feel free to
            drop us a question using the form below
          </p>
        </header>
        <div id='faq-boundary-element' className={css.grid}>
          <div className={css.left}>
            <div className={css.faqItem}>
              <h2>What is Web3 ticketing?</h2>
              <p>
                <span className={css.block}>
                  Web3 ticketing refers to the use of blockchain technology,
                  specifically NFTs
                </span>{' '}
                (Non-Fungible Tokens), in the creation, distribution, and
                management of event{' '}
                <span className={css.block}>
                  tickets. Unlike traditional tickets, NFT tickets are unique
                  digital assets that
                </span>{' '}
                are indivisible, tamper-proof, and stored on a blockchain. Each
                NFT ticket is distinct, providing a secure and verifiable method
                of ticketing. This innovation{' '}
                <span className={css.block}>
                  introduces benefits such as enhanced security, transparency,
                  and the ability
                </span>{' '}
                to incorporate programmable features, transforming the way
                events are{' '}
                <span className={css.block}>organised and attended.</span>
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>
                <span className={css.block}>What kind of events</span> is
                TicketMeta suitable for?
              </h2>
              <p>
                TicketMeta caters for events of all sizes, including concerts,
                festivals, parties, conferences, and sports matches. It is
                equally suitable for venues like{' '}
                <span className={css.block}>
                  theatres, museums, clubs, and stadiums.
                </span>
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>How secure are transactions on TicketMeta?</h2>
              <p>
                <span className={css.block}>
                  We ensure top-tier security by leveraging blockchain-backed
                  NFT tickets.
                </span>{' '}
                This prevents duplication and counterfeiting, providing a secure
                and{' '}
                <span className={css.block}>
                  immutable ticketing experience.
                </span>
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>How does TicketMeta support marketing initiatives?</h2>
              <p>
                <span className={css.block}>
                  TicketMeta provides insightful analytics to power data-driven
                  marketing
                </span>{' '}
                initiatives. Our platform allows you to track ticket sales,
                revenue, and{' '}
                <span className={css.block}>
                  attendee demographics in real-time. These insights empower you
                  to make
                </span>{' '}
                informed decisions and optimize your event strategy for success.
                We also{' '}
                <span className={css.block}>
                  create all kinds of phygital entertainment to support your
                  marketing efforts.
                </span>{' '}
                For example, we can create interactive AR/VR experiences for
                concerts{' '}
                <span className={css.block}>
                  where attendees can enjoy immersive digital visuals alongside
                  live
                </span>{' '}
                performances.
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>
                Can TicketMeta be integrated into existing ticketing systems?
              </h2>
              <p>
                <span className={css.block}>
                  Yes, TicketMeta is made to be easily integrated with various
                  event
                </span>{' '}
                management systems. Our team of experts can work with you to
                ensure a{' '}
                <span className={css.block}>
                  smooth transition and integration process.
                </span>
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>How does TicketMeta protect the data of event attendees?</h2>
              <p>
                We prioritise the privacy and security of event attendees. We
                employ robust{' '}
                <span className={css.block}>
                  encryption measures and adhere to strict data protection
                  regulations
                </span>{' '}
                including GDPR and CCPA. Personal information is securely stored
                on our{' '}
                <span className={css.block}>
                  blockchain network, ensuring that attendee data remains
                  private and
                </span>{' '}
                inaccessible to unauthorised parties.
              </p>
            </div>
            <div className={css.faqItem}>
              <h2>How can I get in touch with your support team?</h2>
              <p>
                Feel free to reach out to our support team by sending us a mail
                <span className={css.block}>
                  at{' '}
                  <a
                    href='mailto:support@ticketmeta.io'
                    target='__blank'
                    rel='noopener'
                  >
                    support@ticketmeta.io
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className={css.right}>
            <Sticky
              boundaryElement='#faq-boundary-element'
              hideOnBoundaryHit={false}
              wrapperClassName={css.stickyForm}
            >
              <div className={css.cardWrapper}>
                <Card variant='outline'>
                  <div className={css.cardContent}>
                    <h2
                      className={cx(
                        css.cardHeading,
                        'h3',
                        'heading--secondary'
                      )}
                    >
                      Ask a question
                    </h2>
                    <div className={css.getInTouchForm}>
                      <Formik
                        validationSchema={sentAskQuestionValidationSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={sentAskQuestionInitialValues}
                      >
                        {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          isSubmitting,
                          submitCount,
                          errors,
                        }) => {
                          const isSubmited = !!submitCount;

                          return (
                            <Form
                              noValidate
                              onSubmit={handleSubmit}
                              className={css.form}
                            >
                              <Form.Group
                                className={css.formGroup}
                                controlId='validationFormikName'
                              >
                                <Form.Label>
                                  Name<span className='label-required'>*</span>
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder='e.g San Andreas'
                                  name='name'
                                  value={values.name}
                                  onChange={handleChange}
                                  isInvalid={isSubmited && !!errors.name}
                                />

                                <Form.Control.Feedback type='invalid'>
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                className={css.formGroup}
                                controlId='validationFormikEmail'
                              >
                                <Form.Label>
                                  Email<span className='label-required'>*</span>
                                </Form.Label>
                                <Form.Control
                                  type='email'
                                  placeholder='e.g sanandreas@gmail.com'
                                  name='email'
                                  value={values.email}
                                  onChange={handleChange}
                                  isInvalid={isSubmited && !!errors.email}
                                />

                                <Form.Control.Feedback type='invalid'>
                                  {errors.email}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group
                                className={css.formGroup}
                                md='6'
                                controlId='validationFormikCase'
                              >
                                <Form.Label>Your question</Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder='describe your ploblem here'
                                  name='question'
                                  as='textarea'
                                  value={values.question}
                                  onChange={handleChange}
                                  isInvalid={isSubmited && !!errors.question}
                                  className={css.question}
                                />

                                <Form.Control.Feedback type='invalid'>
                                  {errors.question}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <SubmitButton
                                className={cx(
                                  css.submitButton,
                                  'button--border-2'
                                )}
                              >
                                SEND
                              </SubmitButton>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </Card>
              </div>
            </Sticky>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
