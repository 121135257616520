import NiceModal, { bootstrapDialog, useModal } from '@ebay/nice-modal-react';
import Button from 'components/form/Button/Button';

import Modal from 'react-bootstrap/Modal';
import { Close } from './Close';
import cx from 'classnames';
import css from './DemoModal.module.scss';
import { DemoForm } from './demo-form/DemoForm';

export const DemoModal = ({ onSubmit }) => {
  const modal = useModal();

  return (
    <Modal
      {...bootstrapDialog(modal)}
      className={css.modal}
      centered
      aria-labelledby='demo-modal-title'
    >
      <Modal.Header className={css.header}>
        <Close onClick={modal.hide} />
        <Modal.Title id='demo-modal-title' className={css.title}>
          Book a demo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx(css.body, css.demoBody)}>
        <DemoForm onSubmit={onSubmit} />
      </Modal.Body>
      <Modal.Footer className={css.footer}></Modal.Footer>
    </Modal>
  );
};

export const FailureModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <Modal
      {...bootstrapDialog(modal)}
      className={css.modal}
      aria-labelledby='demo-failure-modal-title'
      centered
    >
      <Modal.Header className={css.header} />
      <Modal.Body className={css.body}>
        <div id='demo-failure-modal-title' className={css.notifyTitle}>
          Oops! Something went wrong.
          <br />
          Please try again...
        </div>
        <Button
          text='OKAY'
          color='secondary'
          className={css.okayButton}
          onClick={modal.hide}
        />
      </Modal.Body>
      <Modal.Footer className={css.footer}></Modal.Footer>
    </Modal>
  );
});

export const SuccessModal = NiceModal.create(({ children }) => {
  const modal = useModal();

  return (
    <Modal
      {...bootstrapDialog(modal)}
      className={css.modal}
      aria-labelledby='demo-success-modal-title'
      centered
    >
      <Modal.Header className={css.header} />
      <Modal.Body className={css.body}>
        <div id='demo-success-modal-title' className={css.notifyTitle}>
          {children}
        </div>
        <Button
          text='OKAY'
          color='secondary'
          className={css.okayButton}
          onClick={modal.hide}
        />
      </Modal.Body>
      <Modal.Footer className={css.footer}></Modal.Footer>
    </Modal>
  );
});

export const DemoModalContainer = NiceModal.create(() => {
  const modal = useModal();

  const onSubmitHandler = async (res, values, error) => {
    if (!error) {
      await modal.hide();
      await showSuccessModal();
    } else {
      console.log(error);
      await showFailureModal();
    }
  };

  return <DemoModal onSubmit={onSubmitHandler} />;
});

export const showDemoModal = () => NiceModal.show(DemoModalContainer);

export const showSuccessModal = (
  { children } = {
    children: (
      <>
        Thank you for your request!
        <br />
        We'll contact you soon to schedule a demo session.
      </>
    ),
  }
) =>
  NiceModal.show(SuccessModal, {
    children,
  });

export const showFailureModal = () => NiceModal.show(FailureModal);
