import { ReactComponent as X } from 'icons/x.svg';
import { ReactComponent as Instagram } from 'icons/instagram.svg';
import { ReactComponent as LinkedIn } from 'icons/linkedin.svg';
import { ReactComponent as Telegram } from 'icons/telegram.svg';
import { Link } from 'react-router-dom';
import './footer-menu.styles.css';

export default function FooterMenu() {
  return (
    <div className='row footer-menu'>
      <div className='col col-auto footer-menu__links'>
        <ul className='footer-menu__list'>
          <li className='footer-menu__item'>
            <a
              href='https://t.me/irinaverse'
              target='_blank'
              rel='noreferrer'
              className='footer-menu__link'
            >
              For Investors
            </a>
          </li>

          <li className='footer-menu__item'>
            <Link to='/privacy-policy' className='footer-menu__link'>
              Privacy Policy
            </Link>
          </li>

          <li className='footer-menu__item'>
            <Link to='/cookies-policy' className='footer-menu__link'>
              Cookies Policy
            </Link>
          </li>
        </ul>
      </div>

      <div className='col col-auto footer-menu__contacts'>
        <p className='footer-menu__get-in-touch'>Get in touch</p>
        <a
          className='footer-menu__link footer-menu__link-email'
          href='mailto:info@ticketmeta.io'
          target='__blank'
          rel='noopener'
        >
          info@ticketmeta.io
        </a>
      </div>

      <div className='col col-auto footer-menu__social'>
        <ul className='footer-menu__list'>
          <li className='footer-menu__item'>
            <a
              href='https://twitter.com/TicketMeta_io'
              target='_blank'
              rel='noreferrer'
              className='footer-menu__link footer-menu__link-social'
              aria-label='x.com'
            >
              <X />
            </a>
          </li>

          <li className='footer-menu__item'>
            <a
              href='https://www.instagram.com/ticketmeta_io?igsh=MWV3c2lrbjlibW5jMg=='
              target='_blank'
              rel='noreferrer'
              className='footer-menu__link footer-menu__link-social'
              aria-label='Instagram'
            >
              <Instagram />
            </a>
          </li>

          <li className='footer-menu__item'>
            <a
              href='https://www.linkedin.com/company/ticketmeta/'
              target='_blank'
              rel='noreferrer'
              className='footer-menu__link footer-menu__link-social'
              aria-label='LinkedIn'
            >
              <span className="linkedin-blue"><LinkedIn /></span>
            </a>
          </li>

          <li className='footer-menu__item'>
            <a
              href='https://t.me/+GBoNVTf0asQyNzdi'
              target='_blank'
              rel='noreferrer'
              className='footer-menu__link footer-menu__link-social'
              aria-label='Telegram'
            >
              <Telegram />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
